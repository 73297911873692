import React from "react";
import {Layout, Menu, Row, Col, Avatar, Dropdown} from 'antd';
import UserService from "../services/User";
import DataMenu from "../components/common/Menu/Menu";
import Breadcrumbs from "./BreadcrumbsFromPath";

require("./BasicLayout.css");
const {Header, Content, Footer} = Layout;

const userMenu = (
    <Menu>
        <Menu.Item key="logout">
            <a href="#/logout">
                退出
            </a>
        </Menu.Item>
        <Menu.Item key="changeInfo">
            <a href="#/change_info">
                修改信息
            </a>
        </Menu.Item>
        {/* <Menu.Item key="changePhone">
            <a href="#/change_phone">
                绑定手机
            </a>
        </Menu.Item> */}
        <Menu.Item key="changePassword">
            <a href="#/change_password">
                修改密码
            </a>
        </Menu.Item>
    </Menu>
);

export default function BasicLayout({children}) {
    const name=window.localStorage.getItem("name");
    const logo=window.localStorage.getItem("logo");
    return (<div className='out_layout'>
        <Layout className="layout">
            <Header style={{background: '#DEE4FF'}}>
                <Row>
                    <Col span={20}>
                        <div className="logo">
                            <img src={logo||window.config.logo}/>
                            <span className='name'>{name||window.config.name}</span>
                        </div>
                        <DataMenu
                            data={UserService.getCurrentUserMenu()}
                            mode="horizontal"
                        />
                    </Col>
                    <Col span={4}>
                        <div className="header_right">
                            <Dropdown overlay={userMenu}>
                                <span>
                                    <a className="ant-dropdown-link" style={{fontWeight:"bold",color:"black"}}>
                                    {UserService.isAdmin()?"管理员  ":""}
                                    {UserService.isTeacher()?"教师  ":""}
                                    {UserService.isStudent()?"学生  ":""}
                                    {UserService.getCurrentUserShowName()||UserService.getCurrentUserName()}
                                    </a>
                                </span>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Header>
            {/*<div style={{padding: '0 50px', backgroundColor: "#ffffff"}}>*/}
            {/*    <Breadcrumbs/>*/}
            {/*</div>*/}
            <Content style={{padding: '20px 50px', backgroundColor: "#ffffff"}}>
                <div style={{background: '#fff', minHeight: 600}}>
                    {children}
                </div>
            </Content>

        </Layout>
    </div>)
}