let menuData = [
    {
        "title": "作业箱管理",
        "key":"reportbox_manage",
        "children": [
            {
                "title": "作业箱",
                "url": "/t/report/boxes"
            },{
                "title": "评语库",
                "url": "/t/comment_library"
            },{
                "title": "作业统计",
                "url": "/t/report/statistics"
            },]
    },
    {
        "title": "用户管理",
        "key":"user_manage",
        "children": [
            {
                "title": "名单管理",
                "url": "/a/name_list"
            },
            {
                "title": "导入学生",
                "url": "/a/students",
            },
            {
                "title": "重置密码",
                "url": "/t/password_reset"
            },]
    },
    {
        "title": "课程",
        "url": "/t/courses"
    },
    {
        "title": "排课管理",
        "children": [
            {
                "title": "排课计划",
                "url": "/t/course_schedules"
            },
            {
                "title": "排课记录",
                "url": "/t/schedules_record"
            },
            {
                "title": "课表",
                "url": "/t/schedules_view"
            },
            {
                "title": "实验室签到记录",
                "url": "/a/room_checkin_records",
            },
        ]
    },
    {
        "key": "auto_borrow",
        "title": "物料",
        "children": [
            {
                "title": "类目与库存",
                "url": "/auto_borrow/kinds"
            },
            {
                "title": "借用",
                "url": "/auto_borrow/borrow_goods"
            },
            {
                "title": "批量领取",
                "url": "/auto_borrow/batch_borrow"
            },
            {
                "title": "白名单",
                "url": "/white_list"
            },
            {
                "title": "领取",
                "url": "/auto_borrow/get_goods"
            },
            {
                "title": "归还",
                "url": "/auto_borrow/returnToWarehouse"
            },
            {
                "title": "值班时间",
                "url": "/auto_borrow/borrow_time"
            },
            {
                "title": "维护",
                "url": "/auto_borrow/fault_records"
            },
          
            {
                "title": "物料统计",
                "url": "/auto_borrow/query_records"
            },
            {
                "title": "物料柜",
                'children': [
                    {
                        "title": "物料柜管理",
                        "url": "/auto_borrow/cabinet_manage"
                    },
                    // {
                    //     "title": "物料上架",
                    //     "url": "/auto_borrow/cabinet_shelves"
                    // },
                    {
                        "title": "物料柜预览",
                        "url": "/auto_borrow/cabinet_preview"  
                    },
                ]
            }
        ]
    },
    {
        "title": "资源管理",
        "url": "/s/resource/manage"
    },

    {
        "title": "软件下载",
        "url": "/s/support_download"
    },
    // {
    //     "title": "人员管理",
    //     "children": [
    //         {
    //             "title": "成员管理",
    //             "url": "/a/students"
    //         },

    //     ]
    // },
    {
        "title":"意见建议",
        "url":"https://support.qq.com/product/378305"
    }

];
if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.title!=='排课管理')
}
if(!window.config.is_open_wuliao){
    menuData=menuData.filter(menu=>menu.key!=='records'&&menu.key!=='auto_borrow')
}
module.exports = menuData;
